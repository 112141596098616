import { $ } from '../common/variables'
import {triggerTopMessageAdaptation} from "./adaptLayoutToTopBar";

const MIX_API_URL = process.env.MIX_API_URL || 'https://stores-roger-production.com';

function isOnLeave(vacancies) {
  const today = new Date();
  return vacancies.some(vacancy => {
    const startVacancyDate = new Date(vacancy.start);
    const endVacancyDate = new Date(vacancy.end);
    return today >= startVacancyDate && today <= endVacancyDate;
  });
}

/**
 * Format date as 'Lundi 2 octobre 2024"
 * @param {number|string|Date|VarDate} dateString
 */
function formatDate(dateString) {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  return new Date(dateString).toLocaleDateString('fr-FR', options);
}


/**
 * Display and format dates if no custom message is provided
 * @param vacancy - object from api
 */
function displayBannerDates(vacancy) {
  const startDateSpan = document.querySelector('.topMessage .leave-start')
  const endDateSpan = document.querySelector('.topMessage .leave-end')

  if(startDateSpan && endDateSpan) {
    startDateSpan.textContent = formatDate(vacancy.start)
    endDateSpan.textContent = formatDate(vacancy.end)
  }
}

// Fonction principale pour gérer le bandeau de congés et faire le call api de StoreRogerConfigurator (Laravel)
async function getVacancyDate() {
  try {
    const response = await fetch(`${MIX_API_URL}/api/vacancy-dates`, {
      method: 'GET',
      credentials: 'include', // Important for sending cookies
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // Do NOT set Access-Control-Allow-Origin here
      }
    });

    if (!response.ok) {
      console.error(`Erreur HTTP - statut: ${response.status}`);
    }

    const body = document.querySelector('body')
    const mainHeader = document.querySelector('.mainHeader')
    const banner = document.querySelector('.topMessage')
    const data = await response.json();
    const currentVacancy = data.vacancies.find(vacancy => isOnLeave([vacancy]));

    banner.classList.remove('active-leaves')
    body.classList.remove('active-leaves')
    mainHeader.classList.remove('active-leaves')

    if (currentVacancy) {
      const bannerMessage = document.querySelector('.topMessage .leave-message .principal')
      const bannerParagraph = document.querySelector('.topMessage .leave-message .second')

      if(bannerMessage && currentVacancy.start && currentVacancy.end) {
        banner.classList.add('active-leaves')
        body.classList.add('active-leaves')
        mainHeader.classList.add('active-leaves')

        triggerTopMessageAdaptation()

        if(currentVacancy.message !== "") {
          bannerMessage.innerHTML = ""
          bannerMessage.innerHTML = currentVacancy.message

          if(bannerParagraph && currentVacancy.caption !== "") {
            bannerParagraph.textContent = ""
            bannerParagraph.textContent = currentVacancy.caption
          }
        } else {
          displayBannerDates(currentVacancy);
        }
      }

    } else {
      console.info("Aucune période de congés actuelle.");
    }
  } catch (error) {
    if (error instanceof TypeError) {
      console.error('Erreur réseau:', error.message);
    } else if (error instanceof SyntaxError) {
      console.error('Erreur de parsing JSON:', error.message);
    } else {
      console.error('Erreur lors de la récupération des dates de congés:', error.message);
    }
  }
}

// Exporter la fonction pour pouvoir l'utiliser dans d'autres fichiers
export { getVacancyDate };
