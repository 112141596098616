import { $ } from '../common/variables'

export const triggerTopMessageAdaptation = () => {
  const $topMessage = $('.topMessage.active-leaves')
  const $bodyWithTopMessage = $('body.active-leaves')
  const $mainHeader = $('.mainHeader.active-leaves')

  if ($topMessage.length > 0) {
    const adaptTopMessageHeight = () => {
      const mainHeaderHeight = $mainHeader.outerHeight()
      const topMessageHeight = $topMessage.outerHeight()
      const newHeaderHeight = topMessageHeight + mainHeaderHeight
      $bodyWithTopMessage.css('padding-top', `${newHeaderHeight}px`)
      $mainHeader.css('top', `${topMessageHeight}px`)
      if (window.matchMedia('(max-width: 991.98px)').matches) {
        $('.mainHeader .nav').css('top', `${topMessageHeight + mainHeaderHeight}px`)
      }
    }

    adaptTopMessageHeight()

    let rtime
    let timeout = false
    const delta = 300

    jQuery(window).resize(function () {
      rtime = new Date()
      if (timeout === false) {
        timeout = true
        setTimeout(resizeend, delta)
      }
    })

    function resizeend() {
      if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta)
      } else {
        timeout = false
        adaptTopMessageHeight()
      }
    }
  }
}
