import { putScrollbarSizeInCSSVariables } from './common/functions'
import { triggerTopMessageAdaptation } from './components/adaptLayoutToTopBar'
import { menuBurger } from './components/menuBurger'
import { openConfigurator, openFancyboxes } from './components/openFancyboxes'
import { scrollToAnchor } from './components/scrollToAnchor'
import { getVacancyDate } from "./components/setLeaveBanner";

async function initApp() {
  putScrollbarSizeInCSSVariables()
  menuBurger()
  scrollToAnchor()
  openConfigurator()
  openFancyboxes()

  await getVacancyDate()
}

window.addEventListener('DOMContentLoaded', (event) => {
  initApp().catch(error => console.error('Erreur lors de l\'initialisation de l\'application:', error))
})
